@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

button {
  padding: 0px;
}

:root {
  --main: #0d55a0;
  --complementary: #b9b9b9;
  --dark: black;
  --light: white;
  --bg-light: #f5f5f5;
}

.text-center {
  text-align: center;
}

.text-main {
  color: var(--main);
}

.text-complementary {
  color: var(--complementary);
}
