.infoWrapper
  padding: 24px
  border: 1px solid var(--main)
  border-radius: 8px
  margin-top: 32px
  background-color: white
  .listTitle
    margin: 0px 0px 16px 0px
    font-size: 24px
    font-weight: 800
  ul
    list-style: none
    margin: 0
    padding: 0
  li + li
    margin-top: 0px
  .itemHeader
    font-weight: 500
  .itemInfo
    font-weight: 300
.btnsWrapper
  display: flex
  margin-top: 32px
  align-items: stretch
  a
    width: 100%
  a:first-child
    margin-right: 8px
  a:last-child
    margin-left: 8px
.batteryIcon
  display: inline-block
  width: 25px
