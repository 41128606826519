.wrapper
  padding: 16px
  border-radius: 4px
  background-color: white
  span
    display: block
  .label
    font-weight: 500
  .data
    font-size: 20px
    color: var(--main)
    &.disabled
      color: var(--complementary)
