.container
  & > div
    display: flex
    border: solid 1px var(--complementary)
    border-radius: 10px
    overflow: hidden
    max-width: 150px
    margin: 0 auto
    input
      display: block
      width: 100%
      text-align: center
      font-size: 30px
      border: none
      border-right: solid 1px var(--complementary)
      color: var(--main)
      font-weight: 100
    & > div
      display: flex
      flex-direction: column
      button
        padding: 15px 20px
        font-size: 20px
        background-color: white
        border: none
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        background-image: url('../../../assets/imgs/caret-down-icon.svg')
        background-repeat: no-repeat
        background-position: center
        background-size: 10px
        &:first-child
          border-bottom: solid 1px var(--complementary)
          background-image: url('../../../assets/imgs/cartet-up-icon.svg')
