.wrapper
    min-height: 100vh
    max-width: 800px
    box-shadow: 0 0 3px rgba(0, 0, 0, .2)
    margin: 0 auto
    position: relative
    background-color: var(--bg-light)
    .content
        padding: 0px 16px
        padding-bottom: 110px
