.infoWrapper
  padding: 16px
  border-radius: 4px
  margin-top: 32px
  display: flex
  justify-content: space-between
  background-color: white
h3
  margin-top: 32px
  font-weight: 400
.panelInfo
  padding: 16px
  border-radius: 4px
  background-color: white
.textConfirmation
  margin: 32px 0px 10px
  font-size: 14px
  text-align: center
  .data
    color: var(--main)
.dividerText
  margin: 16px 0px

/* The container */
.container
  display: block
  position: relative
  padding-left: 35px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  display: flex
  align-items: center
  height: 25px

/* Hide the browser's default checkbox */
.container input
  position: absolute
  opacity: 0
  cursor: pointer
  height: 0
  width: 0

/* Create a custom checkbox */
.checkmark
  position: absolute
  top: 0
  left: 0
  height: 25px
  width: 25px
  border-radius: 4px
  background-color: #eee
  border: 1px solid var(--complementary)

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark
  // background-color: #ccc
  border: 1px solid var(--main)

/* When the checkbox is checked, add a var(--main) background */
.container input:checked ~ .checkmark
  background-color: var(--main)

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after
  content: ""
  position: absolute
  display: none

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after
  display: block

/* Style the checkmark/indicator */
.container .checkmark:after
  left: 9px
  top: 5px
  width: 5px
  height: 10px
  border: solid white
  border-width: 0 3px 3px 0
  -webkit-transform: rotate(45deg)
  -ms-transform: rotate(45deg)
  transform: rotate(45deg)
