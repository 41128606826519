
.wrapper
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px 10px
  border-radius: 4px
  text-align: center
  cursor: pointer
  box-sizing: border-box
  height: 100%
  justify-content: center
  .label
    font-weight: 500
    font-size: 16px
    text-decoration: none
  svg
    height: 50px
  svg + .label
    margin-top: 0px
  &.light
    background-color: white
    svg
      display: block
      width: auto
    .label
      color: var(--main)
  &.dark
    background-color: var(--main)
    svg
      display: block
      path
        stroke: white
    .label
      color: white
  &.shadow
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.37)
  .label
    padding: 0px 8px
  &.sm
    svg
      height: 20px
  &.md
    .label
      font-size: 11px
    svg
      height: 50px
  &.back-btn
    padding: 8px
    &.shadow
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2)
    svg
      height: 12px
  &.disabled
    opacity: .5
    &.dark
      background-color: var(--complementary)
      cursor: not-allowed
