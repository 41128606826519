.wrapper
  position: fixed
  width: 100%
  bottom: 0
  max-width: 800px
  svg path
    stroke: var(--complementary)
  .active
    svg path
      stroke: var(--main)

.menu-container
  display: flex
  width: 100%
  background-color: white
  justify-content: space-around
  padding: 8px 0px

.poweredBy
  color: var(--main)
  font-size: .75rem
  text-align: center
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 5px 0
  background: var(--bg-light)
  a
    line-height: 0px
    display: inline-block
    margin-left: 5px

.versionBadge
  display: inline-block
  margin-right: 5px
