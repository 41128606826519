.spinner
  border: 4px solid rgba(0, 0, 0, 0.1)
  width: 36px
  height: 36px
  border-radius: 50%
  border-left-color: var(--main)
  animation: spin 1s ease infinite
  display: inline-block

@keyframes spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
