.title
  position: relative
  padding: 16px 0px
  margin: 0px 0px 32px 0px
  font-family: 'Poppins', sans-serif
  font-size: 20px
  font-weight: 500
  &::after
    position: absolute
    content: ''
    bottom: 0
    left: 0
    height: 2px
    width: 80px
    background-color: var(--main)
